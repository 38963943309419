import React from 'react';

const Build = () => {
	return (
		<div>
			<center>Build: 1.1.0901</center>
		</div>
	);
};

export default Build;
