import React from "react"
import 'css/SlideList.css';



export default class Slide extends React.Component {

	constructor() {
		super();
	}

	render() {

		const slide = this.props.slide;

		let css = {};

		if (slide.css.backgroundImage) {
			css.backgroundImage = slide.css.backgroundImage
		}

		if (slide.css.backgroundSize) {
			css.backgroundSize = slide.css.backgroundSize
		}

		const image = slide.imageList && slide.imageList.length > 0 ? slide.imageList[0] : null; // använder bara första bilden. 

		const readMoreLabel = slide.readMore && slide.readMore.label && slide.readMore.label != "" ? slide.readMore.label : "Läs mer";
		const readMoreTarget = slide.readMore && slide.readMore.target && slide.readMore.target != "" ? slide.readMore.target : '_self';

		let readMore = slide.readMore ? <div id="readMore"><a href={slide.readMore.href} title={'Läs mer på: ' + slide.readMore.href} target={readMoreTarget}>{readMoreLabel}</a></div> : null;

		const contentDesktop = <div className="contentDesktop">
			<h1>{slide.title}</h1>
			<p dangerouslySetInnerHTML={{ __html: slide.html }}></p>
			{readMore}
		</div>

		const contentMobile = <div className="contentMobile">

			{image ? <img src={image.src} /> : ''}
			<p dangerouslySetInnerHTML={{ __html: slide.html }}></p>

		</div>

		let desktop = null;
		let mobile = <div id="mobile"><h1>MOBILE {slide.title}</h1>{contentMobile}{readMore}</div>



		if (image) {
			desktop = <div id="desktop" className="image"><div className="contentDesktopWrapper">{contentDesktop}</div><div><img src={image.src} /></div></div>
		} else {
			desktop = <div id="desktop">{contentDesktop} </div>
		}
		return (

			<div className="slide" style={css} >

				{desktop}
				{mobile}

			</div>
		)
	}
}