import React from "react"

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import OrderRow from "./OrderRow";
import button_down from '../../gfx/button_down_dark.png';
import button_up from '../../gfx/button_up_dark.png';
import button_check from '../../gfx/button_check.png';
import { NavLink, useParams } from 'react-router-dom'

import XLSX from 'xlsx';



var Constants = require('../Constants');

export default class Order extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			//order: DataStore.getOrder(),
			//locked: this.props.order && this.props.order.orderStatus && this.props.order.orderStatus.status > 10 ? true : false,
			contactPerson: this.props.order.contactPerson,
			localId: this.props.order.localId,
			text: this.props.order.text,
			importOpen: false,
			importFileOpen: false,
			importData: "",
			importMessageList: [],
			addArtnr: "",
			addQuantity: "",
			edited: false,
		}


	}

	shouldComponentUpdate() {
		return true
	}

	onVisibleClick() {

		const visible = this.state.visible

		this.setState({ visible: !visible })
	}

	onDeleteClick() {
		if (!window.confirm("Vill du radera ordern?")) {
			return;
		}

		DataActions.order(DataStore.getLoginToken(), { action: "deleteOrder", orderId: this.props.order.orderId });
		DataActions.setOrderId(null);
	}

	onSendClick() {


		if (this.state.edited) {
			if (window.confirm("Du har ändrat orderinformation (referensnummer, märkning eller meddelande) utan att spara.\n\nVi rekomenderar att du klickar Avbryt, sparar orderinformationen och sen skickar ordern igen. \n\nKlicka OK för att skicka ordern ändå (utan orderinformation)?")) {
				// skicka ändå
			} else {
				return;
			}
		} else if (!window.confirm("Vill du skicka ordern?")) {
			return;
		}

		this.setState({ edited: false })
		DataActions.order(DataStore.getLoginToken(), { action: "sendOrder", orderId: this.props.order.orderId })
	}

	onAddProductsClick(event) {
		//alert(this.props.order.orderId);
		DataActions.setOrderId(this.props.order.orderId)
		this.setState({ visible: true })
		event.stopPropagation();
		//DataStore.orderId = null;
		//alert("add:" + this.props.order.orderId);
	}

	componentDidMount() {

		DataStore.on(Constants.IMPORT_MESSAGES_CHANGED, () => {
			this.setState({ importMessageList: DataStore.getImportMessageList() })
		})

		DataStore.on(Constants.IMPORT_ONE_PRODUCT_COMPLETE, () => {
			this.setState({ addArtnr: "" })
			this.setState({ addQuantity: "" })
		})
	}

	onProductTypeMCClick() {
		DataActions.selectProductType(1);
	}


	onProductTypeCykelClick() {
		DataActions.selectProductType(2);
	}

	onProductTypeMotulClick() {
		DataActions.selectProductType(3);
	}


	shouldComponentUpdate(nextProps, nextState) {

		//console.log("nextProps", nextProps);

		if (this.props.order.orderId != nextProps.order.orderId) {
			//this.state.visible = false  
			this.setState({ visible: false })
			this.setState({ importOpen: false })
			this.setState({ addArtnr: "" })
			this.setState({ addQuantity: "" })
		}

		return true
	}

	onSaveDetailsClicked() {
		this.setState({ edited: false });
		DataActions.order(DataStore.getLoginToken(), {
			action: "updateOrder",
			orderId: this.props.order.orderId,
			contactPerson: this.state.contactPerson,
			localId: this.state.localId,
			text: this.state.text
		})
	}

	onContactPersonChange(event) {
		this.setState({ edited: true });
		this.setState({ contactPerson: event.target.value })
	}

	onLocalIdChange(event) {
		this.setState({ edited: true });
		this.setState({ localId: event.target.value });
	}

	onTextChange(event) {
		this.setState({ edited: true });
		this.setState({ text: event.target.value })
	}

	onImportHeaderClick() {
		this.setState({ importOpen: !this.state.importOpen })
	}

	handleImportDataChange(event) {
		this.setState({ importData: event.target.value });
	}

	handleAddArtnrChange(event) {
		this.setState({ addArtnr: event.target.value });
		this.artnrInputField = event.target // sparar en referens till artnr-fältet så det kan fokuseras sen
	}

	handleAddQuantityChange(event) {
		this.setState({ addQuantity: event.target.value });
	}

	onImportClick() {

		if (!this.state.importData || this.state.importData.length == 0) {
			return;
		}

		DataActions.removeImportMessages();

		this.state.importData.split("\n").map((row) => {

			const cols = row.split(";");

			if (cols[0] && cols[1]) {
				DataActions.order(DataStore.getLoginToken(),
					{
						"orderId": this.props.order.orderId,
						"action": "importProduct",
						"artnr": cols[0],
						"quantity": cols[1]
					})

				//DataActions.addImportMessage(this.props.order.orderId, "Product: " + cols[0] + " antal: " + cols[1] + " OK", Constants.IMPORT_MESSAGE_OK);

			} else {
				DataActions.addImportMessage(this.props.order.orderId, "FEL FORMAT: " + row, Constants.IMPORT_MESSAGE_ERROR);
			}




		})
	}

	onAddClick() {
		if (this.state.addArtnr.length < 1) {
			alert("Ange artikelnummer");
			return;
		}

		if (this.state.addQuantity.length < 1) {
			alert("Ange antal");
			return;
		}

		DataActions.order(DataStore.getLoginToken(),
			{
				"orderId": this.props.order.orderId,
				"action": "importOneProduct",
				"artnr": this.state.addArtnr,
				"quantity": this.state.addQuantity
			})


		if (this.artnrInputField) {
			this.artnrInputField.focus()
		}
	}

	onCleanClick() {
		DataActions.removeImportMessages(this.props.order);
	}

	onImportFileOpen() {
		this.setState({ importFileOpen: !this.state.importFileOpen })
	}

	onEnterPressed(event) {
		if (event.key === 'Enter') {
			this.onAddClick();
		}
	}

	showFile = async (e) => {
		e.preventDefault()

		const reader = new FileReader()
		reader.onload = async (e) => {
			const text = (e.target.result).trim()
			//console.log(text)
			//alert(text)
			this.setState({ importData: text })


			if (window.confirm("Importera fil?")) {
				this.onImportClick()
			}
		};

		const readerXLS = new FileReader()
		readerXLS.onload = (evt) => { // evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			//const data = XLSX.utils.sheet_to_csv(ws, {header:1});

			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

			var text = "";

			data.forEach(appendRow);

			function appendRow(value) {
				text = text + value[0] + ";" + value[1] + ";\n";
			}

			text = text.trim();

			this.setState({ importData: text })

			if (window.confirm("Importera fil?")) {
				this.onImportClick()
			}

		};

		//alert(e.target.files[0].size)
		//alert(e.target.files[0].type)

		if (e.target.files[0].size > 1000000) {
			alert("Filen är för stor")

		} else if (e.target.files[0].type == "text/plain") {
			reader.readAsText(e.target.files[0])

		} else if (e.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			readerXLS.readAsBinaryString(e.target.files[0]);

		} else if (e.target.files[0].type == "application/vnd.ms-excel") {
			readerXLS.readAsBinaryString(e.target.files[0]);

		} else {
			alert("Felaktigt format, " + e.target.files[0].type + "")
		}
	}



	render() {

		var color = { "background": "grey" }
		var cssClass = "orderStatus status0"
		//var editable = true

		if (this.props.order.orderStatus.status == 10) {
			color = { "background": "#d3460e" }
			cssClass = "orderStatus status10"
			//editable = true

		} else if (this.props.order.orderStatus.status == 20) {
			color = { "background": "#178117" }
			cssClass = "orderStatus status20"

		} else if (this.props.order.orderStatus.status == 30) {
			color = { "background": "#178117" }
			cssClass = "orderStatus status30"
		}

		// DENNA: const selected = this.state.order && this.state.order.orderId == this.props.order.orderId;
		//const selectable = true // this.props.order.orderStatus && this.props.order.orderStatus.status == 10;  

		//const selectable = !this.state.locked

		var artnrInputField = null;

		const saveDetailsButtonClass = this.state.edited ? 'saveButton edited pointer' : 'saveButton pointer';

		return (

			<div className={this.props.selected ? "order selected" : "order"}>

				<div className='orderInformation' onClick={this.onVisibleClick.bind(this)} >
					{!this.props.selected ? <img src={this.state.visible ? button_up : button_down} title={this.state.visible ? "Dölj order" : "Visa order"} className='pointer' /> : null}
					<div className={cssClass}>{this.props.order.orderStatus.label}

						{this.props.order.orderStatus.status == 30 ? <img src={button_check} /> : null}
					</div>

					<h2>Order {this.props.order.orderId} <span className='orderDetails'>{this.props.order.created} - {this.props.order.localId}</span></h2>
					<br />
					{!this.props.locked && this.props.selected ? <div className='addProductsButton selected' >VALD ORDER</div> : null}
					{!this.props.locked && !this.props.selected ? <div className='addProductsButton pointer' onClick={this.onAddProductsClick.bind(this)} title='Välj denna order för att addera produkter eller beställa'>VÄLJ DENNA ORDER</div> : null}

					<div className='clearBoth'></div>



				</div>



				{this.state.visible || this.props.selected ? <div>
					<div className='orderDetails'><h3>Orderinformation</h3>

						<input type='text' placeholder='Ert referensnummer' value={this.state.localId} onChange={this.onLocalIdChange.bind(this)} disabled={this.props.locked} /><br />
						<input type='text' placeholder='Ditt namn' value={this.state.contactPerson} onChange={this.onContactPersonChange.bind(this)} disabled={this.props.locked} /><br />
						<textarea placeholder='Meddelande till Boove' disabled={this.props.locked} onChange={this.onTextChange.bind(this)} value={this.state.text}></textarea><br />
						{!this.props.locked ?
							<button className={saveDetailsButtonClass} disabled={this.props.locked} onClick={this.onSaveDetailsClicked.bind(this)} title='Spara orderinformation'>SPARA</button> : null} <br />

					</div>
					<div className='orderRowList'>
						<h3>Orderrader</h3>
						<div className='orderListHeader'>
							<div className='artnr'>Artikelnummer</div>
							<div className='product'>Benämning</div>
							<div className='stock'>Lager</div>
							<div className='quantity'><div>Antal</div></div>
							<div className='price'>Pris</div>
						</div>
						{this.props.order.orderRowList.map((item, index) => (<OrderRow orderRow={item} orderId={this.props.order.orderId} locked={this.props.locked} key={item.orderRowId} />))
						}

					</div>

					<div className='orderSummary'>
						<h2>Totalt: {this.props.order.orderSummary} kr</h2>


					</div>

					{!this.props.locked ?

						<div className='orderAdd'>
							<h3>Lägg till produkt</h3>
							<input type='text' className='artnr' value={this.state.addArtnr} onChange={this.handleAddArtnrChange.bind(this)} placeholder='Artikelnummer' onKeyPress={this.onEnterPressed.bind(this)} />
							<input type='text' className='quantity' value={this.state.addQuantity} onChange={this.handleAddQuantityChange.bind(this)} placeholder='Antal' onKeyPress={this.onEnterPressed.bind(this)} />
							<button className='orderAddButton pointer' onClick={this.onAddClick.bind(this)} title='Lägg till produkt'>LÄGG TILL</button>
						</div> : null}

					{!this.props.locked ?
						<div className='orderImport'>
							<div className='orderImportHeader ' onClick={this.onImportHeaderClick.bind(this)}>
								<img src={this.state.importOpen ? button_up : button_down} className='pointer' />
								<h3>Lägg in många produkter / Importera fil</h3>
							</div>
							{this.state.importOpen ?
								<div>
									<div className='importButton pointer' onClick={this.onImportFileOpen.bind(this)} title='Importera textfil eller fil från Excel'>IMPORTERA FIL</div>
									{this.state.importFileOpen ? <div className='importFile'>
										<input type="file" onChange={(e) => this.showFile(e)} /><br />
										<br />
										<b>Textfil (.txt)</b><br />
										Formatet på datat ska vara: artikelnummer;antal;<br /><br />
										<b>Excel (.xls eller .xlsx)</b><br />
										I första kolumnen ska artikelnummer finnas i andra kolumnen antal.
                            <table><tr><td>artikelnummer</td><td>antal</td></tr></table>
									</div> : <div />}
									<div className='importData'>
										<h4>Klistra in texten här, skriv manuellt eller importera fil</h4>

										<textarea placeholder='artikelnummer;antal;' value={this.state.importData} onChange={this.handleImportDataChange.bind(this)}></textarea><br />
										<div className='importButton pointer' onClick={this.onImportClick.bind(this)} title='Läs in data'>OK</div>
									</div>
									<div className='importResult'>
										<h4>Resultat av inläsning</h4>
										<div className='importResultList'>
											{this.state.importMessageList && this.state.importMessageList.map((message, index) => {

												if (message.orderId == this.props.order.orderId) {
													return <div key={index} className={message.messageType == Constants.IMPORT_MESSAGE_OK ? 'messageOk' : 'messageError'} title={message.orderId}>{message.message} </div>
												} else {
													return null;
												}

											})}
										</div>
										<div className='importButton pointer' onClick={this.onCleanClick.bind(this)} title='Rensa tidigare inläst data'>RENSA</div>
									</div>
									<div className='clearBoth'></div>
								</div>
								: null}
						</div> : null}

					{!this.props.locked ?
						<div className='toolbar'>
							<div className='deleteButton pointer' onClick={this.onDeleteClick.bind(this)} title='Radera order'>RADERA ORDER</div>

							{this.props.resellerType.length == 1 && this.props.resellerType == "C" ? <div className='addProductsButton pointer' title='Lägg in fler cykel-produkter'><NavLink to='/cykel/list/' >FORTSÄTT HANDLA</NavLink></div> : null}
							{this.props.resellerType.length == 1 && this.props.resellerType == "M" ? <div className='addProductsButton pointer' title='Lägg in fler mc-produkter'><NavLink to='/mc/list/' >FORTSÄTT HANDLA</NavLink></div> : null}
							{this.props.resellerType.length == 1 && this.props.resellerType == "B" ? <div className='addProductsButton pointer' title='Lägg in fler motul-produkter'><NavLink to='/motul/list/' >FORTSÄTT HANDLA</NavLink>/NavLink></div> : null}

							{this.props.resellerType.length > 1 ? <div className='addProductsWrapper'>FORTSÄTT HANDLA
                    {this.props.resellerType.includes("M") ? <div className='addProductsButton pointer' onClick={this.onAddProductsClick.bind(this)} title='Lägg in fler mc-produkter'><NavLink to='/mc/list/' onClick={this.onProductTypeMCClick.bind(this)} >MC</NavLink></div> : null}
								{this.props.resellerType.includes("C") ? <div className='addProductsButton pointer' onClick={this.onAddProductsClick.bind(this)} title='Lägg in fler cykel-produkter'><NavLink to='/cykel/list/' onClick={this.onProductTypeCykelClick.bind(this)}>CYKEL</NavLink></div> : null}
								{this.props.resellerType.includes("B") ? <div className='addProductsButton pointer' onClick={this.onAddProductsClick.bind(this)} title='Lägg in fler motul-produkter'><NavLink to='/motul/list/' onClick={this.onProductTypeMotulClick.bind(this)}>MOTUL</NavLink></div> : null}
							</div> : null}




							<div className='sendButton pointer' onClick={this.onSendClick.bind(this)} title='Skicka in din beställning'>SKICKA BESTÄLLNING</div>
						</div> : <div className='toolbar'><div className='sentButton'>ORDER ÄR INSKICKAD OCH KAN INTE ÄNDRAS</div></div>}

				</div> : null}


			</div>

		)
	}
}