import React from "react"
import DataStore from "../../stores/DataStore";
import 'css/SlideList.css';
import buttonPrevious from 'gfx/button_left_dark_white.png';
import buttonNext from 'gfx/button_right_dark_white.png';
import buttonReload from 'gfx/button_reload.png';
import buttonPause from 'gfx/button_pause.png';
import Slide from "js/components/slide/Slide";
import Constants from "js/Constants";


export default class SlideList extends React.Component {

	constructor() {
		super();

		this.state = {
			activeIndex: 2,
			pause: false,
			timer: null,
			count: 0,
			slideList: DataStore.getStartModel() != null && DataStore.getStartModel().slideList ? DataStore.getStartModel().slideList : [],
			run: false,
			modal: true

		}

		this.ticked = this.ticked.bind(this);

	}

	setup() {

		const startModel = DataStore.getStartModel();

		if (startModel && startModel.slideList && startModel.slideList.length > 0) {
			this.setState({ slideList: startModel.slideList });
			console.log("FP: Startmodel, slideList: ", startModel.slideList)

			if (!this.run) {
				this.onStartPressed(null);
				this.setState({ run: true });
			}
		} else {
			this.setState({ slideList: [] });
		}

	}

	componentDidMount() {
		DataStore.on(Constants.START_MODEL_CHANGED, () => { this.setup() })
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
		DataStore.removeListener(Constants.START_MODEL_CHANGED, () => { this.setup() });

	}

	ticked() {

		if (this.state.slideList.length == 0) {
			console.log("FP: Tick: slideList är tom");
			return;
		}

		if (!this.state.pause) {
			this.setState({ count: this.state.count + 1 });
		}


		if (this.state.count > 5) {
			this.setState({ count: 0 });
			this.next();
		}

		this.setState({ modal: false });


		//console.log("FP: Tick: ", this.state.count);
	}
	previous() {
		let newActiveIndex = this.state.activeIndex;
		newActiveIndex--;

		if (newActiveIndex <= 0) {
			this.setState({ activeIndex: this.state.slideList.length - 1 });
		} else {
			this.setState({ activeIndex: newActiveIndex });
		}



	}
	next() {
		let newActiveIndex = this.state.activeIndex;
		newActiveIndex++;

		if (newActiveIndex >= this.state.slideList.length) {
			this.setState({ activeIndex: 0 });
		} else {
			this.setState({ activeIndex: newActiveIndex });
		}

	}

	onShowPressed(index, event) {
		this.setState({ activeIndex: index });
		this.setState({ pause: true });
	}

	onMouseOver(event) {
		this.setState({ pause: true });
		console.log("FP: Pause: ", this.state.pause)
	}

	onMouseLeave(event) {
		this.setState({ pause: false });
		console.log("FP: Pause: ", this.state.pause)
		this.setState({ count: 0 });
	}

	onStartPressed(event) {

		var timer = setInterval(this.ticked, 1000);
		this.setState({ timer: timer });



	}

	onStopPressed(event) {

		console.log("FP: stopPressed");
		clearInterval(this.state.timer);
	}



	render() {


		const css = { 'display': 'none' }
		const showModal = this.state.modal ? { 'display': 'block' } : { 'display': 'none' }


		return (<div id="slideView">
			<div style={css}>
				<input type="button" value="Start" onClick={this.onStartPressed} />

				<input type="button" value="Stop" onClick={this.onStopPressed} />
				<input type="button" value="Slide 1" onClick={this.onShowPressed.bind(this, 1)} />
				<input type="button" value="Slide 2" onClick={this.onShowPressed.bind(this, 2)} />
				<input type="button" value="Slide 3" onClick={this.onShowPressed.bind(this, 3)} />
				<input type="button" value="<" onClick={this.previous.bind(this)} />
				<input type="button" value=">" onClick={this.next.bind(this)} />
				Count: {this.state.count} Pause: {this.state.pause.toString()} activeIndex: {this.state.activeIndex}
			</div>

			<div id='slideList' onMouseOver={this.onMouseOver.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
				{this.state.slideList.map((slide, index) => (
					<div key={index} className={this.state.activeIndex == index ? 'slideWrapper active news' + index : 'slideWrapper inactive news' + index} >
						<Slide slide={slide} index={index} /> </div>))}

				<div id="modal" style={showModal}>
					<img title='Hämtar nyheter...' src={buttonReload} />
				</div>


				<div id="controllers">
					<div id="previous" onClick={this.previous.bind(this)}><img title='Föregående' src={buttonPrevious} className="poiner" /></div>
					<div id="next" onClick={this.next.bind(this)}><img title='Nästa' src={buttonNext} className="poiner" /></div>
				</div>



			</div>
			<div id="footer">
				&nbsp;
			<div id="dots">
					{this.state.slideList.map((slide, index) => (<div className={this.state.activeIndex == index ? 'active' : 'inactive'} onClick={this.onShowPressed.bind(this, index)} key={index}></div>))}
				</div>
				<div className={this.state.pause ? 'pause on' : 'pause off'}>
					<img src={buttonPause} />
				</div>
			</div>



		</div >)


	}
}